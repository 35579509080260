<script setup lang="ts">
import Axios from 'axios'
import { isElementType } from 'types/dom'

const { $currentStorefront } = useNuxtApp()
const route = useRoute()
const router = useRouter()

const tryAgain = ref(false)
const hasEdited = ref(false)
const incorrectPassword = ref(false)

async function authenticate(event: Event) {
  const form = event.target
  if (!isElementType<HTMLFormElement>(form, 'form'))
    return

  tryAgain.value = false
  incorrectPassword.value = false

  try {
    const redirect = String(route.query?.redirectTo) || 'https://www.happysocks.com'
    const data = new FormData(form)
    await $fetch(`/${$currentStorefront.storefrontCode}/authenticate/user`, {
      method: 'POST',
      body: {
        password: data.get('password'),
        redirectTo: redirect,
      },
    })

    router.push(redirect)
  }
  catch (e) {
    if (!Axios.isAxiosError(e))
      return console.warn(e)

    if (e.response?.status === 401)
      incorrectPassword.value = true
    else
      tryAgain.value = true
  }
}

// TODO: This error message is weird implemented. Need to be fixed (try to click on sign in without editing)
const message = computed<string>(() => {
  if (!hasEdited.value)
    return String(route.query.message) || ''
  if (incorrectPassword.value)
    return 'Incorrect password, try again.'
  if (tryAgain.value)
    return 'Error during request, please try again.'

  return ''
})
</script>

<template>
  <main>
    <form @submit.prevent="authenticate">
      <label>
        <span class="label-text">Password</span>
        <input type="password" name="password" placeholder="Password" @focus="hasEdited = true">
        <ButtonBasic native-type="submit" :jazzy="false">Authenticate</ButtonBasic>
        <p v-if="message" class="message">{{ message }}</p>
      </label>
    </form>
  </main>
</template>

<style lang="scss" scoped>
main {
  display: grid;
  place-items: center;
  min-height: 100vh;
  background: url('~/assets/images/summer.jpg') no-repeat center/cover;
}

form {
  width: 100%;
  padding: 3rem;
  max-width: 40rem;
  background: #fff;
  border: 0.2rem solid var(--black);
}
.label-text {
  display: block;
  margin-bottom: 0.25rem;
}

input {
  width: 100%;
  height: 4.4rem;
  display: block;
  padding: 1.2rem;
  font-size: 1.4rem;
  background: var(--white);
  border: 0.2rem solid var(--black);
}

button,
input {
  margin-bottom: 1rem;
}

.message {
  margin-top: 1rem;
  color: var(--red);
}
</style>
